import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import { isBoolean, delay } from '@st/utils'
import {
  BANNED_MARKETS,
  BANNED_MARKETS_WITH_SPECIFIERS,
} from 'markets-store/constants'
import Decimal from '@st/decimal'
import { useToast } from '@st/ui/composables'
import { useAccountsStore } from '@st/payments/stores/useAccountsStore'
import type { ExtendedBet, ExtendedBetOutcome } from '../types'

interface ActualOutcomes {
  betRate: ExtendedBetOutcome['rate']
  actualRate: ExtendedBetOutcome['rate']
  result: ExtendedBetOutcome['result']
  voidFactor: ExtendedBetOutcome['voidFactor']
  live: boolean
}
type CashoutStatus =
  | 'accepted'
  | 'paused'
  | 'processed'
  | 'unaccepted'
  | 'pending'

interface BetCashoutAmountReturn {
  betCashOutAmount: ComputedRef<string>
}
export function useBetCashoutAmount(
  bet: Ref<ExtendedBet>,
): BetCashoutAmountReturn {
  const MS = useMarketsStore()
  const actualStates = ref(MS.systemStates)
  const isActualFullLoaded = computed<boolean>(
    () => actualStates.value.fullActualLoaded,
  )

  const { settings } = storeToRefs(useSettingsStore())

  const isCashoutAvailablePreliminary = computed(
    () =>
      bet.value.status === 'accepted' &&
      bet.value.accountType === 'real' &&
      ['express', 'ordinary'].includes(bet.value.type),
  )

  function checkIsAvailableMarket(marketId: number, specifiers: string) {
    if (BANNED_MARKETS.has(marketId)) {
      return false
    }

    if (
      BANNED_MARKETS_WITH_SPECIFIERS.has(marketId) &&
      specifiers.match(/\d+\.(0|25|75)/)
    ) {
      return false
    }

    return true
  }
  const availableBetOutcomes = computed(() => {
    /* ждем загрузки полного акутала, чтобы достать событие */
    if (!isActualFullLoaded.value) return []

    const actualOutcomes: ActualOutcomes[] = []
    let availableCashOut = true
    let eventsNotInActualCount = 0

    bet.value.outcomes.forEach((betOutcome) => {
      if (!availableCashOut) return

      const actualEvent =
        betOutcome.event || MS.sportEvents.getById(betOutcome.sbSportEventId)

      /* если события из исхода нет в актуале ( завершилось/ушло из линии ) */
      if (!actualEvent) {
        if (bet.value.type === 'ordinary') {
          availableCashOut = false
        }
        /* 
          возможен вариант, что некоторые события в экспрессе
          закончились, но ставку все еще можно выкупить
        */
        if (bet.value.type === 'express') {
          actualOutcomes.push({
            betRate: betOutcome.rate,
            actualRate: betOutcome.rate,
            result: betOutcome.result,
            voidFactor: betOutcome.voidFactor,
            live: false,
          })
          eventsNotInActualCount += 1
        }
        return
      }

      /* если событие в live проверяем бан маркета на выкуп */
      if (actualEvent.status === 1) {
        availableCashOut = checkIsAvailableMarket(
          betOutcome.marketId,
          betOutcome.specifiers,
        )
      }

      const markets = MS.getMarketsByEventId(betOutcome.sbSportEventId)
      const market = markets.find(
        (m) =>
          m.id === betOutcome.marketId &&
          m.specifiersString === betOutcome.specifiers,
      )
      // @ts-expect-error findById indexedArray
      const outcome = market && market.outcomes.findById(betOutcome.outcomeId)

      /* проверяем статусы маркета и ауткама из акутала */
      if (
        (market?.status !== 1 || !outcome?.active) &&
        !isBoolean(betOutcome.result)
      ) {
        availableCashOut = false
        return
      }

      actualOutcomes.push({
        betRate: betOutcome.rate,
        actualRate: (outcome && outcome.odds) || betOutcome.rate,
        result: betOutcome.result,
        voidFactor: betOutcome.voidFactor,
        live: actualEvent.status === 1,
      })
    })

    if (
      availableCashOut &&
      eventsNotInActualCount < bet.value.outcomes.length
    ) {
      return actualOutcomes
    }
    return []
  })

  function calculateCashOutBetRate(actualOutcomes: ActualOutcomes[]) {
    return actualOutcomes
      .reduce((resultRate, outcome) => {
        const { result, voidFactor, actualRate, betRate } = outcome
        /* Win - betRate * resultRate */
        if (result === true && !voidFactor) {
          return new Decimal(betRate).mul(resultRate)
        }
        /* Half Win - ((betRate - 1) / 2 + 1) * resultRate */
        if (result === true && voidFactor === 'half') {
          return new Decimal(betRate).minus(1).div(2).plus(1).mul(resultRate)
        }
        /* Half Loss - 0.5 * resultRate */
        if (result === false && voidFactor === 'half') {
          return new Decimal('0.5').mul(resultRate)
        }
        /* Refund - 1 * resultRate */
        if (result === false && voidFactor === 'refund') {
          return new Decimal('1').mul(resultRate)
        }
        /* Lose - 0 * resultRate */
        if (result === false && !voidFactor) {
          return new Decimal('0').mul(resultRate)
        }
        /* live/prematch without result - betRate / actualRate * resultRate */
        return new Decimal(betRate).div(actualRate).mul(resultRate)
      }, new Decimal('1'))
      .toString()
  }

  function getSystemRateForCashout(
    cashoutBetRate: string,
    originalBetRate: string,
  ) {
    if (!settings.value) return 0

    const isAllOutcomesInPrematch = availableBetOutcomes.value.every(
      ({ live, result }) => !live && !isBoolean(result),
    )

    const decimalCashoutRate = new Decimal(cashoutBetRate)
    let betCashOutRate
    /* преобразование cashoutBetRate в зависимости от системных настроек для prematch */
    if (isAllOutcomesInPrematch) {
      if (decimalCashoutRate.greaterThan(1)) {
        betCashOutRate = settings.value.betCashOutPrematchCoefficient
      } else if (decimalCashoutRate.equals(1)) {
        betCashOutRate = settings.value
          .betCashOutPrematchCoefficientWhenBetRateNotChangedEnabled
          ? settings.value.betCashOutPrematchCoefficient
          : 1
      } else {
        betCashOutRate = decimalCashoutRate.mul(
          settings.value.betCashOutPrematchCoefficient,
        )
      }
      /* преобразование cashoutBetRate в зависимости от системных настроек для live */
    } else {
      const unsettledOutcomes = availableBetOutcomes.value.filter(
        ({ result }) => !isBoolean(result),
      )
      const unsettledOutcomesNumber = unsettledOutcomes.length

      /*
        ((betCashOutLiveCoefficient * originalBetRate) / cashoutBetRate) **
          betCashOutDegree *
        betCashOutUnsettledOutcomesNumberMultiplier **
          unsettledOutcomesNumber
      */
      const coefficient = new Decimal(settings.value.betCashOutLiveCoefficient)
        .mul(originalBetRate)
        .div(cashoutBetRate)
        .pow(settings.value.betCashOutDegree)
        .mul(
          new Decimal(
            settings.value.betCashOutUnsettledOutcomesNumberMultiplier,
          ).pow(unsettledOutcomesNumber),
        )

      betCashOutRate = decimalCashoutRate.mul(coefficient)
    }

    return betCashOutRate
  }
  const betCashOutAmount = computed(() => {
    if (
      !isCashoutAvailablePreliminary.value ||
      !availableBetOutcomes.value.length ||
      !settings.value
    )
      return '0'

    const cashoutBetRate = calculateCashOutBetRate(availableBetOutcomes.value)
    const originalBetRate = bet.value.originalRate
    const systemCashOutRate = getSystemRateForCashout(
      cashoutBetRate,
      originalBetRate,
    )

    const result = new Decimal(bet.value.amount)
      .mul(systemCashOutRate)
      .toString()

    return result
  })

  return {
    betCashOutAmount,
  }
}

interface BetCashoutReturn {
  betCashOutAmount: ComputedRef<string>
  cashoutAmountWithCurrency: ComputedRef<string>
  cashoutStatus: Ref<'idle' | 'confirm' | 'cashout' | 'success'>
  cashOutAmountFromBackend: ComputedRef<string>
  cashoutBackendAmountWithCurrency: ComputedRef<string>
  cashoutInfoStatus: Ref<string>
  fullCashoutStatus: Ref<string>
  cashOutBet: () => Promise<void>
  startCashoutProcess: () => Promise<void>
}
export function useBetCashout(bet: Ref<ExtendedBet>): BetCashoutReturn {
  const { open } = useToast()
  const { t } = useI18n()
  const cashoutStatus = ref<'idle' | 'confirm' | 'cashout' | 'success'>('idle')
  const { betCashOutAmount } = useBetCashoutAmount(bet)
  const { realAccounts } = storeToRefs(useAccountsStore())
  const currencyCode = computed(
    () =>
      realAccounts.value.find(
        (item) => item.currencyId === bet.value.currencyId,
      )?.code ?? '',
  )
  const { format: formatWithCurrency } = useCryptoFormatter({
    currency: currencyCode,
  })
  const cashoutAmountWithCurrency = computed(() =>
    formatWithCurrency(betCashOutAmount.value),
  )

  const {
    data: betCashoutInfo,
    execute: getCashoutInfo,
    status: cashoutInfoStatus,
    error: cashoutInfoError,
  } = useStFetch('/bet-cash-out/get', {
    method: 'POST',
    immediate: false,
    watch: false,
    body: computed(() => ({
      betId: bet.value.id,
    })),
  })
  const cashOutAmountFromBackend = computed(
    () => betCashoutInfo.value?.fullAmount ?? '0',
  )
  const cashoutBackendAmountWithCurrency = computed(() =>
    formatWithCurrency(cashOutAmountFromBackend.value),
  )

  async function startCashoutProcess() {
    await getCashoutInfo()

    if (!cashoutInfoError.value) {
      cashoutStatus.value = 'confirm'
      await delay(5000)
      if (cashoutStatus.value === 'confirm') {
        cashoutStatus.value = 'idle'
      }
    } else {
      open({
        label: t('bets.cashoutError'),
        type: 'negative',
      })
    }
  }

  const {
    execute: makeFullCashout,
    status: fullCashoutStatus,
    error: cashoutError,
  } = useStFetch('/bet-cash-out/full', {
    method: 'POST',
    immediate: false,
    watch: false,
    body: computed(() => ({
      betId: bet.value.id,
      betCashOutAmount: cashOutAmountFromBackend.value,
    })),
  })

  const io = useSocket()
  function waitForCashoutStatusSocket() {
    return new Promise<boolean>((resolve) => {
      const socketListener = (payload: {
        betId: number
        status: CashoutStatus
      }) => {
        if (payload.betId !== bet.value.id) return

        if (payload.status === 'processed') {
          io.off('betCashOutStatus', socketListener)
          resolve(true)
        } else if (payload.status === 'unaccepted') {
          io.off('betCashOutStatus', socketListener)
          resolve(false)
        }
      }

      io.on('betCashOutStatus', socketListener)
    })
  }

  async function cashOutBet() {
    cashoutStatus.value = 'cashout'

    try {
      await makeFullCashout()

      if (cashoutError.value) {
        open({
          label: t('bets.cashoutError'),
          type: 'negative',
        })
        cashoutStatus.value = 'idle'
        return
      }

      /** выкуп без задержки ( prematch ) */
      if (!betCashoutInfo.value?.acceptDelay) {
        open({
          label: t('bets.cashoutSuccess', [
            cashoutBackendAmountWithCurrency.value,
          ]),
          type: 'positive',
        })
        cashoutStatus.value = 'success'
        return
      }
      /** выкуп c задержкой ( live ) */
      const isAcceptedBySocket = await waitForCashoutStatusSocket()
      if (isAcceptedBySocket) {
        open({
          label: t('bets.cashoutSuccess', [
            cashoutBackendAmountWithCurrency.value,
          ]),
          type: 'positive',
        })
        cashoutStatus.value = 'success'
      } else {
        open({
          label: t('bets.cashoutError'),
          type: 'negative',
        })
        cashoutStatus.value = 'idle'
      }
    } catch (error) {
      console.error('Casout failed', error)
      open({
        label: t('bets.cashoutError'),
        type: 'negative',
      })
      cashoutStatus.value = 'idle'
    }
  }

  return {
    betCashOutAmount,
    cashoutAmountWithCurrency,
    cashoutStatus,
    cashOutAmountFromBackend,
    cashoutBackendAmountWithCurrency,
    cashoutInfoStatus,
    fullCashoutStatus,
    cashOutBet,
    startCashoutProcess,
  }
}
