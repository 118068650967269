import { isEqual } from '@st/utils'
import type { FindBetParams, ExtendedBet, BetStatus, Bet } from '../types'
import { useUpdateBetsBySbData } from './useUpdateBetsBySbData'

interface FindHistoryBetsReturn {
  canLoadMore: Ref<boolean>
  loadMoreBets: () => void
  reset: () => void
  extendedBets: Ref<ExtendedBet[]>
  pending: Ref<boolean>
  isEmpty: Ref<boolean>
}

export function useFindHistoryBets(
  params: Ref<FindBetParams> | FindBetParams = {
    status: ['paused', 'accepted'],
  },
): FindHistoryBetsReturn {
  const currentPage = ref(0)
  const extendedBets = ref<ExtendedBet[]>([])

  const body = computed(() => ({
    params: unref(params),
    pagination: {
      page: currentPage.value,
      perPage: 10,
      orderBy: [
        {
          fieldName: 'createdAt' as const,
          sortOrder: 'DESC' as const,
        },
      ],
    },
  }))

  const {
    data: bets,
    execute: fetchBets,
    pending: isLoadingApi,
    error,
  } = useStFetch('/bet/find', {
    method: 'post',
    body,
    watch: false,
    immediate: false,
  })

  const total = computed(() => (bets.value ? bets.value.paging.total : 0))

  async function loadMoreBets() {
    currentPage.value += 1
    fetchBets()
  }

  async function reset() {
    extendedBets.value = []
    currentPage.value = 0
    fetchBets()
  }

  watch(
    () => unref(params),
    () => {
      reset()
    },
  )

  const { updateBetOutcomes } = useUpdateBetsBySbData()

  const isLoadingSb = ref(false)

  watch(
    () => bets.value,
    async (newBets) => {
      const betsResult = newBets?.data ?? []
      isLoadingSb.value = true
      const updatedBySb = await updateBetOutcomes(
        betsResult as unknown as Bet[],
      )
      isLoadingSb.value = false

      extendedBets.value.push(...updatedBySb)
    },
  )

  const isInGameBets = computed(() =>
    isEqual(['paused', 'accepted'], unref(params).status),
  )

  const io = useSocket()
  function findBetListener(payload: { betId: number; betStatus: BetStatus }) {
    const currentBet = extendedBets.value.find(({ id }) => id === payload.betId)
    if (!currentBet) return

    if (['processed'].includes(payload.betStatus) && isInGameBets.value) {
      extendedBets.value = extendedBets.value.filter(
        ({ id }) => id !== currentBet.id,
      )
    }
  }
  onMounted(() => {
    fetchBets()
    io.on('betStatus', findBetListener)
  })

  onBeforeUnmount(() => {
    io.off('betStatus', findBetListener)
  })

  const isLoading = computed(() => isLoadingApi.value || isLoadingSb.value)
  const isEmpty = computed(() => !isLoading.value && !extendedBets.value.length)
  const canLoadMore = computed(
    () =>
      !error.value &&
      !isLoading.value &&
      total.value > extendedBets.value.length,
  )

  return {
    canLoadMore,
    loadMoreBets,
    reset,
    extendedBets,
    pending: isLoading,
    isEmpty,
  }
}
