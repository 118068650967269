import type { TMatchStats } from 'markets-store/types'
import { parseLanguageMap, sortBy, truncRate } from '@st/utils'
import { useAccountsStore } from '@st/payments/stores/useAccountsStore'
import Decimal from '@st/decimal'
import type { IconName } from '@st/ui/components/StIcon/types'
import type { ExtendedBet, Bet, BetOutcome, ExtendedBetOutcome } from '../types'

interface HistoryOutcomeReturn {
  competitorsNames: ComputedRef<{ home: string; away: string }>
  marketName: ComputedRef<string>
  outcomeName: ComputedRef<string>
  historyMatchResult: ComputedRef<string>
  historyScore: ComputedRef<string>
  selfStatusInExpress: ComputedRef<{
    name: IconName | null
    color: string
  }>
  isRefunded: ComputedRef<boolean>
}
export function useHistoryOutcome(
  outcome: Ref<BetOutcome | ExtendedBetOutcome>,
): HistoryOutcomeReturn {
  const i18n = useI18n()

  const competitors = computed(() => ({
    home: outcome.value.competitors?.find((item) => item.qualifier === 'home'),
    away: outcome.value.competitors?.find((item) => item.qualifier === 'away'),
  }))
  const competitorsNames = computed(() => ({
    home: parseLanguageMap(
      competitors.value.home?.name ?? '',
      i18n.locale.value,
    ),
    away: parseLanguageMap(
      competitors.value.away?.name ?? '',
      i18n.locale.value,
    ),
  }))
  const marketName = computed(() =>
    parseLanguageMap(
      outcome.value.marketName as Record<string, string>,
      i18n.locale.value,
    ),
  )
  const outcomeName = computed(() =>
    parseLanguageMap(
      outcome.value.outcomeName as Record<string, string>,
      i18n.locale.value,
    ),
  )
  const historyMatchResult = computed(() => {
    const stats = outcome.value.matchStats as TMatchStats
    const periodScores = stats?.period_scores ?? []
    const sortedScores = sortBy(periodScores, (period) => [period.number])

    return sortedScores
      .map((score) => `${score.home_score}:${score.away_score}`)
      .join()
  })
  const historyScore = computed(() => {
    const stats = outcome.value.matchStats as TMatchStats

    return stats.home_score ? `${stats.home_score}:${stats.away_score}` : ''
  })
  const isRefunded = computed(() => outcome.value.voidFactor === 'refund')
  const selfStatusInExpress = computed(() => {
    switch (outcome.value.result) {
      case false: {
        if (isRefunded.value) {
          return {
            name: 'refund' as IconName,
            color: '#FFFFFF7A',
          }
        }
        return {
          name: 'circle-cross' as IconName,
          color: '#FF4247',
        }
      }
      case true:
        return {
          name: 'circle-check-solid' as IconName,
          color: '#3FDC62',
        }
      default:
        return {
          name: 'circle-clock-solid' as IconName,
          color: '#FFFFFF7A',
        }
    }
  })

  return {
    competitorsNames,
    marketName,
    outcomeName,
    historyMatchResult,
    historyScore,
    selfStatusInExpress,
    isRefunded,
  }
}

interface HistoryBetReturn {
  resultAmount: ComputedRef<string>
  statusIcon: ComputedRef<{
    name: IconName
    color: string
  }>
  currencyIcon: ComputedRef<IconName>
  currencyCode: ComputedRef<string>
  createdAt: ComputedRef<string>
  betWinLoseStatus: ComputedRef<'pending' | 'cashout' | 'win' | 'lose'>
  resultText: ComputedRef<string>
  betRate: ComputedRef<string>
}

export function useHistoryBet(bet: Ref<ExtendedBet | Bet>): HistoryBetReturn {
  const accountStore = useAccountsStore()
  const { realAccounts } = storeToRefs(accountStore)
  const { format } = useCryptoFormatter()
  const { parseDate } = useDate()
  const { t } = useI18n()

  const createdAt = computed(() =>
    parseDate(bet.value.createdAt).format('DD MMMM, hh:mm'),
  )
  const currentCurrency = computed(() =>
    realAccounts.value.find((item) => item.currencyId === bet.value.currencyId),
  )
  const currencyIcon = computed(() => currentCurrency.value?.icon as IconName)
  const currencyCode = computed(() => currentCurrency.value?.code ?? '')

  // ожидаемая выплата включая кэф
  const payoffAmount = computed(() =>
    new Decimal(bet.value.amount)
      .times(new Decimal(bet.value.originalRate))
      .toString(),
  )
  // ожидаемый чистый выйгрыш
  const cleanWinAmount = computed(() =>
    new Decimal(bet.value.amount)
      .times(new Decimal(bet.value.originalRate).minus(1))
      .toString(),
  )
  const betWinLoseStatus = computed(() => {
    if (['accepted', 'paused'].includes(bet.value.status)) {
      return 'pending'
    }
    if (bet.value.status === 'processed') {
      if (
        bet.value.payoff &&
        new Decimal(bet.value.payoff).greaterThan(0) &&
        new Decimal(bet.value.payoff).lessThan(payoffAmount.value)
      )
        return 'cashout'

      if (new Decimal(bet.value.payoff ?? '0').greaterThan(0)) return 'win'

      return 'lose'
    }
    return 'pending'
  })
  const statusIcon = computed(() => {
    switch (betWinLoseStatus.value) {
      case 'lose':
        return {
          name: 'circle-cross' as IconName,
          color: '#FF4247',
        }
      case 'win':
        return {
          name: 'circle-check-solid' as IconName,
          color: '#3FDC62',
        }
      case 'cashout':
        return {
          name: 'circle-check-solid' as IconName,
          color: '#3FDC62',
        }
      default:
        return {
          name: 'circle-clock-solid' as IconName,
          color: '#FFFFFF7A',
        }
    }
  })
  const payoffByAccountType = computed(() =>
    bet.value.accountType === 'real'
      ? format(Number(payoffAmount.value))
      : format(Number(cleanWinAmount.value)),
  )
  const resultAmount = computed(() => {
    switch (betWinLoseStatus.value) {
      case 'lose':
        return `-${format(Number(bet.value.amount))}`
      case 'win':
        return `+${format(Number(bet.value.payoff))}`
      case 'cashout':
        return `+${format(Number(bet.value.payoff))}`
      default:
        return payoffByAccountType.value // pending status
    }
  })
  const resultText = computed(() => {
    if (betWinLoseStatus.value === 'cashout') return t('bets.cashout')
    return bet.value.status === 'processed' ? t('bets.result') : t('bets.pay')
  })

  /* 
    при наличии factRate нужно выводить его
    т.к итоговый кэф может изменинтся при возврате, отмене ставки 
  */
  const betRate = computed(() =>
    Number(bet.value.factRate)
      ? truncRate(Number(bet.value.factRate))
      : truncRate(Number(bet.value.originalRate)),
  )

  return {
    resultAmount,
    statusIcon,
    currencyIcon,
    currencyCode,
    createdAt,
    betWinLoseStatus,
    resultText,
    betRate,
  }
}
