import type { TSportEvent } from 'markets-store/types'
import type { Bet, ExtendedBet, ExtendedBetOutcome, SbEntities } from '../types'

interface NamesMap {
  id: number
  name: string
}
interface NamesByType {
  names: NamesMap[]
  type: 'sbSportName' | 'sbCategoryName' | 'sbTournamentName'
}

export function useUpdateBetsBySbData() {
  const i18n = useI18n()
  const MS = useMarketsStore()

  function getIds(items: Bet[]) {
    const { sportIds, categoryIds, tournamentIds } = items.reduce(
      (acc, { outcomes }) => {
        outcomes.forEach((outcome) => {
          if (outcome.sbSportId) acc.sportIds.add(outcome.sbSportId)
          if (outcome.sbCategoryId) acc.categoryIds.add(outcome.sbCategoryId)
          if (outcome.sbTournamentId)
            acc.tournamentIds.add(outcome.sbTournamentId)
        })
        return acc
      },
      {
        sportIds: new Set<number>(),
        categoryIds: new Set<number>(),
        tournamentIds: new Set<number>(),
      },
    )

    return {
      sportIds,
      categoryIds,
      tournamentIds,
    }
  }
  async function getSbNames({
    ids,
    type,
  }: {
    ids: Set<number>
    type: 'Sports' | 'Categories' | 'Tournaments'
  }) {
    try {
      const { rows } = await MS.client[`find${type}`]({
        pageSize: 1000,
        query: {
          id: {
            $in: Array.from(ids),
          },
        },
        fields: ['id', 'name'],
        lang: i18n.locale.value,
        fallbackLang: MS.getFallbackLangForLang(MS.lang),
      })

      return rows as unknown as NamesMap[]
    } catch {
      console.error('Failed to load Sb names')
      return []
    }
  }
  function setSbDataToBetOutcomes({
    items,
    names,
  }: {
    items: Bet[]
    names: NamesByType[]
  }): ExtendedBet[] {
    return items.map((bet) => ({
      ...bet,
      outcomes: bet.outcomes.map((outcome) => {
        const extendedOutcome: ExtendedBetOutcome = {
          ...outcome,
          event: MS.sportEvents.getById(outcome.sbSportEventId) as TSportEvent,
        }
        names.forEach((row) => {
          const idKey = row.type.replace('Name', 'Id') as SbEntities

          extendedOutcome[row.type] = row.names.find(
            (name) => name.id === outcome[idKey],
          )?.name
        })

        return extendedOutcome
      }),
    }))
  }
  async function updateBetOutcomes(items: Bet[]) {
    const { sportIds, categoryIds, tournamentIds } = getIds(items)

    try {
      const names = await Promise.all([
        getSbNames({ ids: sportIds, type: 'Sports' }).then((resp) => ({
          names: resp,
          type: 'sbSportName' as NamesByType['type'],
        })),
        getSbNames({ ids: categoryIds, type: 'Categories' }).then((resp) => ({
          names: resp,
          type: 'sbCategoryName' as NamesByType['type'],
        })),
        getSbNames({ ids: tournamentIds, type: 'Tournaments' }).then(
          (resp) => ({
            names: resp,
            type: 'sbTournamentName' as NamesByType['type'],
          }),
        ),
      ])

      return setSbDataToBetOutcomes({ items, names })
    } catch {
      return items
    }
  }

  return {
    updateBetOutcomes,
  }
}
