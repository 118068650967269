<template>
  <StButton
    v-if="props.cashoutStatus === 'idle'"
    size="m"
    :loading="props.cashoutInfoStatus === 'pending'"
    :block="props.fullWidth"
    @click="emits('startCashoutProcess')"
  >
    <span>
      {{ t('bets.makeCashout') }}
      <span data-t="front-cashout"> {{ props.amount }}</span>
    </span>
  </StButton>
  <StButton
    v-else
    class="confirm-button"
    size="m"
    type="secondary"
    :block="props.fullWidth"
    :loading="props.cashoutStatus === 'cashout'"
    @click="emits('cashOutBet')"
  >
    <span class="text-cashout">{{ t('bets.confirmCashout') }}</span>
    <span data-t="back-cashout">{{ props.backAmount }}</span>
  </StButton>
</template>

<script setup lang="ts">
interface Props {
  cashoutInfoStatus: string
  cashoutStatus: 'idle' | 'confirm' | 'cashout' | 'success'
  amount: string
  backAmount: string
  fullWidth?: boolean
}
const props = defineProps<Props>()

const { t } = useI18n()

const emits = defineEmits<{
  (e: 'startCashoutProcess'): void
  (e: 'cashOutBet'): void
}>()
</script>

<style scoped>
.text-cashout,
.back-cashout {
  white-space: nowrap;
}

.confirm-button {
  flex-wrap: wrap;
}

.back-cashout {
  margin-left: var(--spacing-050);
}
</style>
